import liff from '@line/liff'

export const line = () => {
  liff.init({liffId: '2002989587-Go8D3Vwk'}).then(() => {
  }).catch(() => {
  })

}
/* export const line = async() => {
  await liff.init({ liffId: "2002989587-Go8D3Vwk" });
  if(liff.isInClient()) return

  if(!liff.isLoggedIn()){
    liff.login({ redirectUri: 'https://betflixgame.super8989.com/' })
  }
}; */

export const canActive = liff.ready.then(() => {
  return liff.isInClient()
 })