import Vue from "vue";
import VueRouter from "vue-router";
import liff from '@line/liff'

Vue.use(VueRouter);



const routes = [
  { 
    path: "/", 
    name: "Index",
    redirect: "Game" 
  },
  
  {
    path: "/game",
    name: "Game",
    component: () => import("../views/Game.vue"),
    beforeEnter: (to, from, next) => {
        if(liff.isInClient()){
          next()
        } else {
          next()
        }
    }
  },
  {
    path: "/404",
    name: "Notfound",
    component: () => import("../components/NotFound.vue"),
  },
  {
    path: "*",
    redirect: "404",
  },
];





const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
